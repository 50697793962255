<template><div>
  <b-row>
    <b-col cols="12">
      <b-form-group
        label="Stripe Billing Information Setup"
        class="font-weight-bold"
      />
      <b-form-group label="Base URL">

        <validation-provider
          #default="{ errors }"
          name="Base URL"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-input-group-prepend is-text>
              <feather-icon
                class="cursor-pointer"
                icon="GlobeIcon"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="form.url"
              :state="errors.length > 0 ? false:null"
              class="form-control-merge"
              placeholder="Stripe API URL"
            />

          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label="Client ID">

        <validation-provider
          #default="{ errors }"
          name="Client ID"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-input-group-prepend is-text>
              <feather-icon
                class="cursor-pointer"
                icon="UserIcon"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="form.clientId"
              :state="errors.length > 0 ? false:null"
              class="form-control-merge"
              placeholder="Stripe Client ID"
            />

          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label="Client Secret">
        <validation-provider
          #default="{ errors }"
          name="Client ID"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
            :state="errors.length > 0 ? false:null"
          >
            <b-form-input
              v-model="form.clientSecret"
              :state="errors.length > 0 ? false:null"
              class="form-control-merge"
              :type="passwordFieldType"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>

        </validation-provider>

      </b-form-group>

    </b-col>

  </b-row>
</div></template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  props: {
    mode: {
      type: String,
      default: () => '',
    },
    information: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        url: 'http://api.stripe.com',
        clientId: '',
        clientSecret: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    mode: {
      handler(val) {
        if (val === 'edit') {
          this.form = this.information
        }
      },
      immediate: true,
    },
  },
}
</script>

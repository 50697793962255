<template>
  <b-card>
    <billing-info-form
      v-if="showBillingInfoForm"
      :form-data="selectedInfo"
      :country-option="countryOption"
      :editing-for="selectedInfo ? selectedInfo.__typename : null"
      @refetch="() => { $emit('refetch'); selectedInfo = null; showBillingInfoForm = false }"
      @cancel="onCancel"
    />

    <b-row v-else>
      <b-col
        v-for="data in userInfoData"
        :key="data.id"
        md="4"
      >
        <b-card border-variant="primary">
          <div class="d-flex justify-content-between">
            <div>
              <h6>{{ data.alias }}</h6>
              <p class="mb-0">
                {{ data.bankAccount }}
              </p>
            </div>
            <div>
              <b-badge
                class="edit-btn cursor-pointer"
                @click="() => { selectedInfo = {...data}; showBillingInfoForm = true }"
              >
                <feather-icon icon="Edit2Icon" />
              </b-badge>
            </div>
          </div>

          <p class="mb-2">
            {{ data.bankName }}
          </p>
          <b-card-footer class="text-right pb-0">
            {{ getBillingType(data.__typename) }}
          </b-card-footer>
        </b-card>
      </b-col>

      <!-- Add New Info -->
      <b-col
        md="4"
        @click="() => { showBillingInfoForm = true }"
      >
        <div
          class="new-wallet-wrap text-center cursor-pointer centralize"
          @click="() => { showBillingInfoForm = true }"
        >
          <feather-icon
            icon="PlusIcon"
            size="42"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardFooter, BBadge } from 'bootstrap-vue'
import BillingInfoForm from './BillingInfoForm.vue'

export default {
  components: {
    BCard,
    BCardFooter,
    BBadge,
    BillingInfoForm,
  },
  props: {
    userInfoData: {
      type: Array,
      default: () => [],
    },
    countryOption: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showBillingInfoForm: false,
      selectedInfo: null,
    }
  },
  watch: {
    userInfoData: {
      handler(val) {
        if (val.length && this.$route.params.id) {
          this.selectedInfo = val.find(data => data.id === this.$route.params.id)
          if (this.selectedInfo) this.showBillingInfoForm = true
        }
      },
      immediate: true,
    },
  },
  methods: {
    getBillingType(type) {
      switch (type) {
        case 'AchBillingInformation':
          return 'ACH'
        case 'FxBillingInformation':
          return 'FX'
        default:
          return 'SWIFT'
      }
    },
    onCancel() {
      if (this.$route.params.fromPage === 'accounts') this.$router.back()
      this.showBillingInfoForm = false
      this.selectedInfo = null
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
.card {
  min-height: 185px;
}
</style>

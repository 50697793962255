<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <h4>
        {{ formData ? `Edit Billing Information of ${formData.alias}` : 'Add New Billing Information' }}
      </h4>

      <b-form-group class="col-md-6">
        <v-select
          v-if="!editingFor"
          id="countryList"
          v-model="paymentType"
          placeholder="Select Payment Type"
          style="width: 100%"
          label="name"
          :reduce="item => item.code"
          :options="paymentTypes"
        />
      </b-form-group>
    </div>
    <div v-if="paymentType || editingFor">
      <validation-observer ref="simpleRules">
        <b-form>
          <swift-form
            v-if="paymentType=='swift' || editingFor=='BillingInformation'"
            :country-option="countryOption"
            :is-processing="isProcessing"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="$emit('cancel')"
          />
          <ach-form
            v-if="paymentType=='ach' || editingFor=='AchBillingInformation'"
            :country-option="countryOption"
            :is-processing="isProcessing"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="$emit('cancel')"
          />
          <fx-form
            v-if="paymentType=='fx' || editingFor=='FxBillingInformation'"
            :country-option="countryOption"
            :is-processing="isProcessing"
            :form-data="form"
            @validationForm="validationForm"
            @cancel="$emit('cancel')"
          />
        </b-form>
      </validation-observer>
    </div>
    <div
      v-else
      class="justify-content-center pt-4 d-flex align-items-center"
    >
      <h4>Please Select one Payment Type!!!!</h4>
    </div>
  </b-card>
</template>

<script>
import { ValidationObserver, extend } from 'vee-validate'
import {
  BFormGroup, BForm, BCard,
} from 'bootstrap-vue'
import { required, email } from 'vee-validate/dist/rules'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import SwiftForm from './billing.vue/SwiftForm.vue'
import AchForm from './billing.vue/AchForm.vue'
import FxForm from './billing.vue/FxForm.vue'

export default {
  components: {
    ValidationObserver,
    BFormGroup,
    BForm,
    BCard,
    vSelect,
    SwiftForm,
    AchForm,
    FxForm,
  },
  props: {
    formData: {
      type: Object,
      default: () => [],
    },
    countryOption: {
      type: Array,
      default: () => [],
    },
    editingFor: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      form: {},
      emailValue: '',
      name: '',
      required,
      email,
      clevePhone: {
        phone: true,
      },
      paymentTypes: [
        {
          code: 'swift',
          name: 'Swift',
        },
        {
          code: 'ach',
          name: 'ACH',
        },
        {
          code: 'fx',
          name: 'Fx',
        },
      ],
      isProcessing: false,
      paymentType: null,
    }
  },
  watch: {
    userInfoData(newValue) {
      if (newValue[0]) {
        const userInfo = { ...newValue[newValue.length - 1] }
        userInfo.country = userInfo.country?.code
        this.userInfo = userInfo
      }
    },
  },
  created() {
    extend('swiftCode', value => {
      if (value.length > 11) {
        return 'The Bank Swift Code must be less than 11 characters'
      } if (value.split(' ').length > 1) {
        return 'The Bank Swift Code cannot contain space'
      }
      return true
    })

    if (this.formData) {
      this.form = {
        ...this.formData,
        country: this.formData.country?.code,
      }
    }
  },
  methods: {
    validationForm(param) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true 
          const form = param

          if (form.__typename === 'AchBillingInformation') this.paymentType = 'ach'
          else if (form.__typename === 'FxBillingInformation') this.paymentType = 'fx'
          delete form.__typename
          delete form.country.__typename

          useApollo.users.postBillingSetting({
            userUid: getUserData().uuid,
            input: form,
          }, this.paymentType).then(() => {
            this.$store.dispatch('project/setInformationStatus', {
              hasBankingInformation: true,
            })
            this.showSuccess('Billing updated successfully!')
            this.$emit('refetch')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
  },
}
</script>

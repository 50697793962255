<template>
  <b-overlay :show="checkingPermissions || savingTimezone">
    <b-skeleton-wrapper
      v-if="checkingPermissions"
      :loading="checkingPermissions"
    >
      <template #loading>
        <b-card>
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </b-card>
      </template>
    </b-skeleton-wrapper>
    <div v-else>
      <no-permission v-if="!hasPermissions" />
      <div v-else>
        <div class="d-flex justify-content-between align-items-center">
          <h4>Company Settings</h4>
          <manage-permissions
            v-if="canManagePermissions"
            :permissions="permissions"
            class="mb-2"
          />
        </div>
        <b-tabs
          v-model="activeTab"
          nav-class="nav-left"
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          nav-wrapper-class="col-md-3 col-12"
          pills
          vertical
        >
          <b-tab v-if="canEditCompany">
            <template #title>
              <span class="font-weight-bold">
                <feather-icon
                  icon="SettingsIcon"
                />
                General Settings
              </span>
            </template>

            <company-form
              :mode="mode"
              :company-uid="companyUid"
              :time-zones="timeZones"
            />
          </b-tab>
          <b-tab
            v-if="canEditCompany"
            @click="getCountries"
          >
            <template #title>
              <span class="font-weight-bold">
                <i class="fas fa-university" />
                Bank Details
              </span>
            </template>

            <account-setting-banking
              :user-info-data="companyBillingInfo"
              :country-option="countries"
            />
          </b-tab>
          <b-tab v-if="canEditTimezones">
            <template #title>
              <span class="font-weight-bold">
                <feather-icon icon="MapIcon" />
                Timezone Settings
              </span>
            </template>

            <b-card>
              <b-row
                v-if="mode !== 'create'"
                class="align-items-center"
              >
                <b-col sm="10">
                  <h4 class="mb-2">
                    Timezone Settings
                  </h4>
                  <b-form-group label="Timezone offset">
                    <v-select
                      v-model="reportingTimezones"
                      multiple
                      :options="timeZones"
                      :reduce="zone => zone.name"
                      label="title"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="pt-lg-3">
                  <label>&nbsp;</label>
                  <b-button
                    variant="outline-primary"
                    :disabled="savingTimezone"
                    @click="updateTimezone"
                  >
                    <b-spinner
                      v-show="savingTimezone"
                      small
                    />
                    Update
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab v-if="canEditCompanyBilling">
            <template #title>
              <span class="font-weight-bold">
                <feather-icon icon="DollarSignIcon" />
                Payment Gateways
              </span>
            </template>

            <b-card>
              <payment-gateways />
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="font-weight-bold">
                <feather-icon icon="ClockIcon" />
                Payment Notification
              </span>
            </template>

            <b-overlay :show="isProcessing">
              <b-card>
                <h4 class="mb-2">
                  Payment Notification
                </h4>
                <pay-day
                  @updateInput="updatePayDay"
                />
              </b-card>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import {
  VBModal, BFormGroup, BSpinner, BButton, BCard, BTabs, BTab, BOverlay, BRow, BCol, BSkeletonWrapper, BSkeleton,
} from 'bootstrap-vue'
import EventBus from '@/event-bus'
import vSelect from 'vue-select'
import useApollo from '@/plugins/graphql/useApollo'
import { checkPermission } from '@/utils/permissions'
import ManagePermissions from '@/views/common/components/ManagePermissions.vue'
import NoPermission from '@/views/common/components/NoPermission.vue'
import { mapActions, mapGetters } from 'vuex'
import AccountSettingBanking from '@/views/common/UserSettings/components/AccountSettingBanking.vue'
import CompanyForm from './CompanyForm.vue'
import PayDay from './PayDay.vue'
import PaymentGateways from './PaymentGateways.vue'

export default {
  components: {
    BFormGroup,
    vSelect,
    BCard,
    BSpinner,
    BButton,
    BTabs,
    BTab,
    BCol,
    BRow,
    BOverlay,
    BSkeletonWrapper,
    BSkeleton,
    CompanyForm,
    ManagePermissions,
    NoPermission,
    PayDay,
    PaymentGateways,
    AccountSettingBanking,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    companyUid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: 'My Company',
      isProcessing: false,
      timeZones: [],
      showingProcessing: false,
      reportingTimezones: [],
      savingTimezone: false,
      permissions: ['timezones.update', 'company.edit', 'company.billing.edit'],
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
      checkingPermissions: false,
      needPermissionCheck: false,
      companyBillingInfo: [],
      countries: [],
      activeTab: 0,
    }
  },
  computed: {
    ...mapGetters('permissions', [
      'canEditCompany',
      'canEditTimezones',
      'canEditCompanyBilling',
      'getPermissionsCount',
    ]),
    hasPermissions() {
      return this.canEditCompany || this.canEditTimezones || this.canEditCompanyBilling
    },
    company() {
      return this.$store.state.project.selectedCompany
    },
  },
  mounted() {
    EventBus.$on('permission-updated', permission => {
      if (this.permissions.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => this.checkUserPermissions(), 2000)
      }
    })
    this.checkUserPermissions()
    this.getMyCompanyTimeZone()
    this.getCompanyBillingInfo()
    this.getAllTimezones()

    setTimeout(() => {
      const { hash } = this.$route
      if (this.canEditCompany && hash === '#banking') this.activeTab = 1
    }, 500)
  },
  methods: {
    ...mapActions('permissions', ['setPermissions']),
    getAllTimezones() {
      useApollo.company.getTimeZone().then(response => {
        this.timeZones = response.data.timezones?.data.map(item => ({ name: item.name, title: item.displayName }))
      }).catch(error => {
        console.log(error)
      })
    },
    getCompanyBillingInfo() {
      useApollo.company.getCompanyBillingInfo({
        companyUid: this.company,
      }).then(response => {
        this.companyBillingInfo = response.data.company?.extraInformations?.data?.map(row => ({
          ...row.information,
        }))
      })
    },
    getMyCompanyTimeZone() {
      useApollo.company.getMyCompanyTimeZone({ companyUid: this.company }).then(response => {
        this.reportingTimezones = response.data.me.companies?.data[0].timezones?.data.map(timezone => ({
          name: timezone.name,
          title: timezone.displayName,
        }))
      })
    },
    checkUserPermissions() {
      if (this.getPermissionsCount(this.permissions) !== this.permissions.length || this.needPermissionCheck) {
        this.checkingPermissions = true
        checkPermission(this.permissions, permissions => {
          const canEditTimezones = permissions['timezones.update'] === true
          const canEditCompany = permissions['company.edit'] === true
          const canEditCompanyBilling = permissions['company.billing.edit'] === true

          const payload = [
            { key: 'setCompanyEditPermission', value: canEditCompany },
            { key: 'setTimezonesEditPermission', value: canEditTimezones },
            { key: 'setCompanyBillingPermission', value: canEditCompanyBilling },
          ]
          this.setPermissions(payload)

          this.$nextTick(() => {
            this.checkingPermissions = false
            this.needPermissionCheck = false
          })
        })
      }
    },
    addMoreReportingTimezone() {
      this.reportingTimezones.push('')
    },
    updateTimezone() {
      this.savingTimezone = true
      useApollo.company.updateCompanyTimezone({
        companyUid: this.$store.state.project.selectedCompany,
        timezones: this.reportingTimezones,
      }).then(() => {
        this.showSuccess('Timezone Updated')
      }).catch(error => {
        console.log(error)
      }).finally(() => { this.savingTimezone = false })
    },
    updatePayDay(setting) {
      this.isProcessing = true
      useApollo.payroll.updatePayDay({
        companyUid: this.$store.state.project.selectedCompany,
        input: { type: setting.type, configuration: setting.value },
      }).then(response => {
        this.showSuccess(response.data?.updateCompanyPaymentSetting?.message)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    getCountries() {
      useApollo.company.getCountries().then(response => {
        this.countries = response.data.countries.data
      })
    },
  },
}
</script>
